import { marked } from "marked"

export const capitalizeText = (text) => {
  if (!text) {
    return text
  }
  return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
}

export const capitalizeAllText = (text) => {
  if (!text) {
    return text
  }
  return text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ")
}

export const copyText = (id, str) => {
  /* Get the text field */
  var copyText = document.getElementById(id)

  /* Select the text field */
  copyText.select()
  copyText.setSelectionRange(0, 99999) /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy")
}

export function copyString(text) {
  var tempTextInput = document.createElement("input")
  tempTextInput.value = text
  document.body.appendChild(tempTextInput)
  tempTextInput.select()

  document.execCommand("copy")
  document.body.removeChild(tempTextInput)
}

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const removeArticles = (str) => {
  if (!str) {
    return ""
  }
  const words = str.toLowerCase().split(" ")
  if (words.length <= 1) return str
  if (words[0] === "a" || words[0] === "the" || words[0] === "an") {
    return words.splice(1).join(" ")
  }
  return str
}

export const markdownToHTML = (text) => {
  return marked.parse(text)
}

export const joinWithAnd = (ar) => {
  return ar.length > 1
    ? `${ar.slice(0, -1).join(", ")}${ar.length > 2 ? "," : ""} and ${ar.slice(
        -1,
      )}`
    : { 0: "", 1: ar[0] }[ar.length]
}
