import gql from "graphql-tag"
import { mediumProblemFragment } from "../../guides/graphql/mediumProblemFragment"
import { mediumRouteFragment } from "../../guides/graphql/mediumRouteFragment"
import { basicGuideFragment } from "../../guides/graphql/basicGuideFragment"
import { basicAreaFragment } from "../../guides/graphql/basicAreaFragment"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { workoutFragment } from "../../workouts/graphql/workouts"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { basicScannerActivityFragment } from "../../scanners/graphql/scanners"

export const sessionFragment = gql`
  fragment sessionFragment on Session {
    _id
    sessionType
    startDate
    endDate
    notes
    isIndoor
    isWorkoutFinished
    stravaActivityId
    user {
      ...basicUserFragment
    }
    gym {
      _id
      slug
      name
      isOverlappingGrades
      isShowingGrades
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
    guide {
      _id
      slug
      name
      popMedia {
        ...basicMediaFragment
      }
    }
    sends {
      _id
      grade
      slug
      name
      type
      sendType
      tries
      date
      isFromRFID
      user {
        ...basicUserFragment
      }
      problem {
        ...mediumProblemFragment
        area {
          ...basicAreaFragment
          guide {
            ...basicGuideFragment
          }
        }
      }
      route {
        ...mediumRouteFragment
        area {
          ...basicAreaFragment
          guide {
            ...basicGuideFragment
          }
        }
      }
    }
    attempts {
      _id
      date
      isFromRFID
      problem {
        ...mediumProblemFragment
        area {
          ...basicAreaFragment
          guide {
            ...basicGuideFragment
          }
        }
      }
      route {
        ...mediumRouteFragment
        area {
          ...basicAreaFragment
          guide {
            ...basicGuideFragment
          }
        }
      }
      grade
      climbType
    }
    completedExerciseIds
    workout {
      ...workoutFragment
    }
    activity {
      ...basicScannerActivityFragment
    }
    totalMeters
  }
  ${mediumProblemFragment}
  ${mediumRouteFragment}
  ${basicUserFragment}
  ${workoutFragment}
  ${basicMediaFragment}
  ${basicAreaFragment}
  ${basicGuideFragment}
  ${basicScannerActivityFragment}
`

export const GET_SESSIONS = gql`
  query GetSessions(
    $minDate: Date
    $maxDate: Date
    $userId: String!
    $sessionTypes: String
    $cursor: String
    $limit: Int
  ) {
    getSessions(
      minDate: $minDate
      maxDate: $maxDate
      userId: $userId
      sessionTypes: $sessionTypes
      cursor: $cursor
      limit: $limit
    ) {
      items {
        _id
        startDate
        sessionType
        pebblePoints
      }
      cursor
      hasMore
    }
  }
`

export const GET_SESSION = gql`
  query GetSession($id: String!) {
    getSession(id: $id) {
      ...sessionFragment
    }
  }
  ${sessionFragment}
`

export const DELETE_SESSION = gql`
  mutation DeleteSession($sessionId: String!) {
    deleteSession(sessionId: $sessionId) {
      _id
    }
  }
`

export const GET_LATEST_SESSION = gql`
  query GetLatestSession($userId: String) {
    getLatestSession(userId: $userId) {
      ...sessionFragment
    }
  }
  ${sessionFragment}
`

export const GET_CHART_SESSIONS = gql`
  query GetChartSessions($userId: String!, $minDate: Date!, $maxDate: Date!) {
    getChartSessions(userId: $userId, minDate: $minDate, maxDate: $maxDate) {
      _id
      startDate
      sessionType
      pebblePoints
      pebbleIntensity
      totalTime
      restTime
      sendPoints
      percentSends
      isIndoor
      sends {
        _id
        type
      }
      attempts {
        _id
        type
      }
      totalMeters
    }
  }
`

export const CREATE_WORKOUT_SESSION = gql`
  mutation CreateWorkoutSession($workoutId: String!, $climbIds: [String]) {
    createWorkoutSession(workoutId: $workoutId, climbIds: $climbIds) {
      ...sessionFragment
    }
  }
  ${sessionFragment}
`

export const UPDATE_WORKOUT_SESSION = gql`
  mutation updateWorkoutSession($workoutStatus: WorkoutStatusInput!) {
    updateWorkoutSession(workoutStatus: $workoutStatus) {
      ...sessionFragment
      habitsComplete {
        isCompleteOnDay
        canComplete
        habit {
          _id
          name
        }
      }
    }
  }
  ${sessionFragment}
`

export const REMOVE_WORKOUT_FROM_SESSION = gql`
  mutation RemoveWorkoutFromSession($sessionId: String!) {
    removeWorkoutFromSession(sessionId: $sessionId) {
      ...sessionFragment
    }
  }
  ${sessionFragment}
`

export const UPDATE_SESSION_NOTES = gql`
  mutation UpdateSessionNotes($sessionId: String!, $notes: String) {
    updateSessionNotes(sessionId: $sessionId, notes: $notes) {
      ...sessionFragment
    }
  }
  ${sessionFragment}
`

export const SAVE_SESSION_AS_STRAVA_ACTIVITY = gql`
  mutation SaveSessionAsStravaActivity($sessionId: String!) {
    saveSessionAsStravaActivity(sessionId: $sessionId) {
      ...sessionFragment
    }
  }
  ${sessionFragment}
`
