import _ from "lodash"
import {
  CREATE_PROBLEM,
  CREATE_ROUTE,
  ADD_MEDIA,
  CREATE_AREA,
  CREATE_GUIDE,
  CREATE_SEND,
  START_SESSION,
  RESET_GYM_AREA,
  GYM_NOTIFICATION,
  COMP_NOTIFICATION,
  COMP_JUDGE_NOTIFICATION,
  SESSION_WORKOUT,
  GYM_DATA_OPT_IN,
  CREATE_WORKOUT,
  ACHIEVEMENT,
  BETA_VIDEO,
  SCANNER_ACTIVITY,
  SURVEY,
  COMPLETE_PYRAMID,
  GAME_WINNER,
} from "../config/feedItemTypes"
import { getCenterZoomFromFeats } from "./geo"
import { mapAccessToken } from "../config/map"
import { getStaticMapIconURL } from "../utilities/map"
import { getPopMediaThumb } from "./imageUtils"
import { badgeOptions } from "../config/milestones"

function getSendMedia(send) {
  const feature = send[send.sendType]
  return send.media || feature?.popMedia
}

export function getMapImage(feats, width = 340, height = 200) {
  const centerZoom = getCenterZoomFromFeats(feats, [width, height])
  const pinURLAr = feats.reduce((acc, feat) => {
    const coords = feat?.location?.coordinates
    if (!coords) {
      return acc
    }
    const url =
      "url-" +
      getStaticMapIconURL(feat.type, true) +
      "(" +
      coords[0] +
      "," +
      coords[1] +
      ")"
    return [...acc, url]
  }, [])
  return (
    "https://api.mapbox.com/styles/v1/mapbox/satellite-v8/static/" +
    pinURLAr.join(",") +
    "/" +
    centerZoom.center[0] +
    "," +
    centerZoom.center[1] +
    "," +
    Math.min(17, centerZoom.zoom - 1) +
    ",0.00/" +
    Math.round(width) +
    "x" +
    Math.round(height) +
    "@2x?access_token=" +
    mapAccessToken
  )
}

export function getFeedAction({
  feedItem,
  hasActiveCompSets,
  hasActiveJudgedSets,
}) {
  switch (feedItem.eventType) {
    case ADD_MEDIA:
      const [media] = feedItem.media
      return `Added ${
        media?.oembed?.provider_name
          ? media?.oembed?.provider_name === "Instagram"
            ? "an Instagram post"
            : `a ${media?.oembed?.provider_name} video`
          : media?.video
          ? "a video"
          : "a photo"
      }:`
    case CREATE_SEND:
      return feedItem.sends.length === 1 ? "Sent" : "Logged"
    case ACHIEVEMENT:
      return (
        badgeOptions[feedItem.achievementType]?.getFeedItemText(feedItem) || ""
      )
    case START_SESSION:
      const workout = feedItem.session?.workout
      const numSends = feedItem.session?.sends.length
      return workout && numSends && !feedItem.session.guide
        ? "Worked Out & Climbed"
        : numSends === 1
        ? "Sent"
        : "Climbed"
    case SESSION_WORKOUT:
      return "Finished "
    case SCANNER_ACTIVITY:
      return "Completed "
    case RESET_GYM_AREA:
      const { hasProblems, hasRoutes } = feedItem.gymAreas[0]
      const hasClimbs = hasProblems && hasRoutes
      const isBeforeResetFinishes =
        feedItem.gym?.isSendingSettingNotificationsBeforeSettingStarts
      const numClimbs =
        (feedItem?.problems?.length ?? 0) + (feedItem?.routes?.length ?? 0)
      const isSingle = numClimbs === 1
      return `${numClimbs || "New"} ${
        hasClimbs ? "climb" : hasProblems ? "boulder" : "route"
      }${isSingle ? "" : "s"} ${
        isBeforeResetFinishes
          ? `${isSingle ? "is" : "are"} going up on the`
          : `${isSingle ? "was" : "were"} set on the`
      }`
    case COMP_NOTIFICATION:
      if (hasActiveCompSets) {
        return feedItem.title
      }
      return "Checkout our latest comp"
    case COMP_JUDGE_NOTIFICATION:
      if (hasActiveJudgedSets) {
        return feedItem.title
      }
      return "Checkout our latest comp"

    case GYM_NOTIFICATION:
    case GYM_DATA_OPT_IN:
      return feedItem.title
    case CREATE_WORKOUT:
      return feedItem.workout?.guide || feedItem.workout?.gym
        ? "Added the workout: "
        : "Added the workout: "
    case COMPLETE_PYRAMID:
      return "Completed"
    case GAME_WINNER:
      return "Won"
    case CREATE_PROBLEM:
    case CREATE_ROUTE:
    case CREATE_GUIDE:
    case CREATE_AREA:
    default:
      return "Added"
  }
}

export function getFeedImage(feedItem) {
  let media
  let url
  switch (feedItem.eventType) {
    case CREATE_PROBLEM:
    case CREATE_ROUTE:
    case CREATE_AREA:
    case CREATE_SEND:
    case RESET_GYM_AREA:
      const features = feedItem?.problems?.length
        ? feedItem.problems
        : feedItem?.routes?.length
        ? feedItem.routes
        : feedItem?.areas?.length
        ? feedItem.areas
        : feedItem?.guides?.length
        ? feedItem.guides
        : feedItem?.sends?.length
        ? feedItem.sends
        : feedItem?.attempts
        ? feedItem?.gymAreas?.length
        : feedItem?.gymAreas
      media = (features || []).reduce((acc, feature) => {
        let med =
          feature.type === "send"
            ? getPopMediaThumb(getSendMedia(feature))
            : getPopMediaThumb(feature.popMedia)
        if (
          feedItem.eventType === RESET_GYM_AREA &&
          !feature?.popMedia?.isBetaVideo
        ) {
          return acc
        }
        if (med) {
          return [
            ...acc,
            {
              ...med,
              isBetaVideo: feature?.popMedia?.isBetaVideo,
            },
          ]
        }
        if (feature.picture) {
          return [
            ...acc,
            { thumbnailUrl: feature.picture, url: feature.picture },
          ]
        }
        return acc
      }, [])
      if (feedItem.eventType === RESET_GYM_AREA && media.length === 0) {
        return feedItem.gymAreas[0].popMedia
      }
      const mapUrl = getMapImage(features)
      return media.length === 0 ? { thumbnailUrl: mapUrl, url: mapUrl } : media
    case ADD_MEDIA:
    case BETA_VIDEO:
      return feedItem.media.map((med) => getPopMediaThumb(med))
    case ACHIEVEMENT:
    case COMPLETE_PYRAMID:
      return {
        url:
          badgeOptions[feedItem.achievementType]?.getBadge(
            feedItem.achievementValue,
          ) || null,
      }
    case GAME_WINNER:
      return {
        url: "https://asend-assets.s3.us-east-2.amazonaws.com/hive-logo-shadow.png",
      }
    case SURVEY:
      return {
        url: feedItem.survey.picture,
      }
    case CREATE_WORKOUT:
      return feedItem.workout.exercises.reduce((acc, ex) => {
        if (ex.image) {
          return [...acc, { thumbnailUrl: ex.image, url: ex.image }]
        }
        return acc
      }, [])
    // case RESET_GYM_AREA:
    //   media = getPopMediaThumb(
    //     _.find(feedItem.gymAreas, (area) => {
    //       return area.popMedia
    //     }),
    //   )
    //   url = getMapImage(feedItem.areas)
    //   return media || { thumbnailUrl: url, url }
    case START_SESSION: {
      const attOrSends = feedItem.session.sends?.length
        ? feedItem.session.sends
        : feedItem.session.attempts
      const climbType = attOrSends[0]?.climbType || attOrSends[0]?.sendType
      _.each(attOrSends, (send) => {
        const med = getSendMedia(send)
        media = med
        return med === undefined
      })
      if (media) {
        return getPopMediaThumb(media)
      }
      const feats = attOrSends.map((send) => {
        return send[climbType]
      })
      if (feats[0]?.gymArea) {
        return getPopMediaThumb(feats[0].gymArea)
      }
      if (feats[0]?.guide) {
        return getPopMediaThumb(feats[0].guide)
      }
      if (feats[0]?.location?.coordinates) {
        const url = getMapImage(feats)
        return { thumbnailUrl: url, url }
      }
      return { thumbnailUrl: null }
    }
    case COMP_NOTIFICATION:
    case COMP_JUDGE_NOTIFICATION: {
      return feedItem.image
        ? { url: feedItem.image }
        : { url: feedItem.comp.picture }
    }
    case GYM_NOTIFICATION: {
      return feedItem.image ? { url: feedItem.image } : feedItem.gym.popMedia
    }
    case SCANNER_ACTIVITY: {
      return { url: feedItem.session.activity?.image }
    }
    default:
  }
}

export function getMergedFeedId(feedItem, isServer = false) {
  let feat
  switch (feedItem.eventType) {
    case CREATE_PROBLEM:
      feat = feedItem.problems[0]
      break
    case CREATE_ROUTE:
      feat = feedItem.routes[0]
      break
    case CREATE_AREA:
      feat = feedItem.areas[0]
      break
    case CREATE_GUIDE:
      feat = feedItem.guides[0]
      break
    case CREATE_SEND:
      feat = feedItem.sends[0]
      break
    case ADD_MEDIA:
      feat = feedItem.media[0]
      break
    case START_SESSION:
      feat = feedItem.session
      break
    case RESET_GYM_AREA:
      feat = feedItem.gymAreas[0]
      break
    case GYM_NOTIFICATION:
      feat = feedItem.noteId
      break
    case COMP_NOTIFICATION:
      feat = isServer ? feedItem._id : feedItem
      break
    case COMP_JUDGE_NOTIFICATION:
      feat = isServer ? feedItem._id : feedItem
      break
    case SESSION_WORKOUT:
      feat = feedItem.session
      break
    case CREATE_WORKOUT:
      feat = feedItem.workout
      break
    case ACHIEVEMENT:
      feat = isServer ? feedItem._id : feedItem
      break
    case SURVEY:
      feat = feedItem.survey
      break
    case COMPLETE_PYRAMID:
      feat = feedItem.gamePyramid
      break
    case GAME_WINNER:
      feat = feedItem.game
      break
    default:
      feat = isServer ? feedItem._id : feedItem
  }
  return isServer ? feat.toString() : feat._id
}
