import _ from "lodash"
import {
  CREATE_PROBLEM,
  CREATE_ROUTE,
  ADD_MEDIA,
  CREATE_AREA,
  CREATE_GUIDE,
  CREATE_SEND,
  START_SESSION,
  RESET_GYM_AREA,
  GYM_NOTIFICATION,
  COMP_NOTIFICATION,
  COMP_JUDGE_NOTIFICATION,
  SESSION_WORKOUT,
  GYM_DATA_OPT_IN,
  CREATE_WORKOUT,
  ACHIEVEMENT,
  BETA_VIDEO,
  SCANNER_ACTIVITY,
  SURVEY,
  COMPLETE_PYRAMID,
  GAME_WINNER,
} from "../../app/config/feedItemTypes"

export function getFeedFeatureType(feedItem) {
  if (!feedItem) return false
  switch (feedItem.eventType) {
    case CREATE_PROBLEM:
      return "problem"
    case CREATE_ROUTE:
      return "route"
    case ADD_MEDIA:
    case BETA_VIDEO:
      return "media"
    case CREATE_AREA:
      return "area"
    case CREATE_GUIDE:
      return "guide"
    case CREATE_SEND:
    case ACHIEVEMENT:
      return feedItem?.sends?.[0] ? "send" : ""
    case START_SESSION:
      return "session"
    case RESET_GYM_AREA:
      return "gymArea"
    case GYM_NOTIFICATION:
      return "gymNotification"
    case COMP_NOTIFICATION:
    case COMP_JUDGE_NOTIFICATION:
      return "compNotification"
    case SESSION_WORKOUT:
    case SCANNER_ACTIVITY:
      return "session"
    case CREATE_WORKOUT:
      return "workout"
    default:
      return false
  }
}

// allows filtering of feed items if no match is found
// otherwise gets feature that feed item is about
export function getFeedFeatures(feedItem, compSetIds = []) {
  if (!feedItem) return []
  switch (feedItem.eventType) {
    case CREATE_PROBLEM:
      return feedItem.problems
    case CREATE_ROUTE:
      return feedItem.routes
    case ADD_MEDIA:
    case BETA_VIDEO:
      return feedItem.media
    case CREATE_AREA:
      return feedItem.areas
    case CREATE_GUIDE:
      return feedItem.guides
    case CREATE_SEND:
    case ACHIEVEMENT:
      return feedItem?.sends?.[0] ? feedItem.sends : [feedItem.session] || {}
    case START_SESSION:
      return [feedItem.session]
    case RESET_GYM_AREA:
      return feedItem.gymAreas
    case GYM_NOTIFICATION:
      return feedItem.gyms ?? [feedItem.gym]
    case COMP_NOTIFICATION:
    case COMP_JUDGE_NOTIFICATION:
      return feedItem?.comp?.currentCompSets?.length
        ? feedItem?.comp?.currentCompSets
            .filter((cS) => compSetIds.includes(cS._id))
            .reduce((acc, set) => {
              return _.uniqBy([...acc, ...(set.gymAreas || [])], (gA) => gA._id)
            }, [])
        : [feedItem.comp]

    case SESSION_WORKOUT:
      return [feedItem.session.workout]
    case SCANNER_ACTIVITY:
      return [feedItem.session.activity]
    case GYM_DATA_OPT_IN:
      return [
        {
          name: "",
          type: "privacy",
        },
      ]
    case CREATE_WORKOUT:
      return [feedItem.workout]
    case SURVEY:
      return [feedItem.survey]
    case COMPLETE_PYRAMID:
      return [feedItem.gamePyramid]
    case GAME_WINNER:
      return [feedItem.game]
    default:
      return false
  }
}
