import _ from "lodash"
import dayjs from "dayjs"
import React, { Fragment, useState } from "react"
import { withRouter } from "next/router"
import Tooltip from "../../common/components/Tooltip"
import Card from "../../common/components/Card"
import { faComment } from "@fortawesome/pro-light-svg-icons"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import UserListModal from "../../users/components/UserListModal"
import { getFeedAction, getFeedImage } from "../../utilities/feed"
import { getFeedFeatures } from "../../utilities/feedFeatures"
import FeedItemLikesCont from "../containers/FeedItemLikesCont"
import FeedItemLikes from "./FeedItemLikes"
import FeedItemComments from "./FeedItemComments"
import FeedItemStats from "./FeedItemStats"
import SocialImage from "../../common/components/SocialImage"
import ClimbGrade from "../../common/components/ClimbGrade"
import Carousel from "../../common/components/Carousel"
import {
  Box,
  UnorderedList,
  ListItem,
  Text,
  Link,
  Heading,
  Avatar,
  Flex,
  AvatarGroup,
} from "@chakra-ui/react"
import { imageHandler, getResizedImageObj } from "../../utilities/imageHandler"
import { capitalizeText } from "../../utilities/text"
import styles from "./css/FeedItem.module.css"
import {
  GYM_NOTIFICATION,
  COMP_NOTIFICATION,
  COMP_JUDGE_NOTIFICATION,
  ACHIEVEMENT,
  SESSION_WORKOUT,
  CREATE_WORKOUT,
  COMPLETE_PYRAMID,
  GAME_WINNER,
} from "../../config/feedItemTypes"
import Measure from "react-measure"
import ReactPlayer from "react-player"
import { markdownToHTML } from "../../utilities/text"
import { siteURL } from "../../config/env"
import MediaEmbed from "../../media/components/MediaEmbed"
import Cell from "../../common/components/Cell"
import TextLink from "../../common/components/TextLink"
import IconToggle from "../../common/components/IconToggle"
import SessionCard from "../../users/components/SessionCard"
import WorkoutCard from "../../workouts/components/WorkoutCard"
import AbsoluteGradient from "../../common/components/AbsoluteGradient"
import {
  INDOOR_PROBLEM_GRADE,
  INDOOR_ROUTE_GRADE,
} from "src/app/config/milestones"
import useColors from "src/app/common/hooks/useColors"

dayjs.extend(LocalizedFormat)

const Logo = "/images/pebble-logo.svg"

// TODO: I need to clean this whole file up and move logic out to use both on mobile and app
const getTitle = ({
  fType,
  eventType,
  gymArea,
  features,
  featRoute,
  user,
  action,
  item,
}) => {
  if (eventType === GYM_NOTIFICATION) {
    return action
  }
  if (
    eventType === COMP_NOTIFICATION ||
    eventType === COMP_JUDGE_NOTIFICATION
  ) {
    return (
      <>
        {`${action}: `}
        <TextLink to={"/comps/" + item.comp.slug}>{item.comp.name}</TextLink>
      </>
    )
  }
  if (eventType === COMPLETE_PYRAMID) {
    return (
      <>
        {action} a{" "}
        <TextLink to={"/pyramids/" + features[0]._id}>
          Level {item.gamePyramid.level}{" "}
          {item.gamePyramid.climbType === "problem" ? "Bouldering" : "Route"}{" "}
          Pyramid
        </TextLink>{" "}
        at{" "}
        <TextLink to={"/gyms/" + item.gym.slug}>
          {item.gamePyramid.gym.name}
        </TextLink>
      </>
    )
  }
  if (eventType === GAME_WINNER) {
    const { winners, players, gameType } = item.game
    const winnerIds = winners.map((w) => w._id)
    const isTied = winners.length > 1
    const otherPlayers = isTied
      ? winners.filter((w) => w._id !== item.user._id)
      : players.filter((p) => !winnerIds.includes(p._id))
    return (
      <>
        {isTied ? "Tied in" : "Won"} a game of{" "}
        <TextLink to={"/games/" + item.game._id}>
          {capitalizeText(gameType)}
        </TextLink>
        {isTied ? " with " : " against "}
        {otherPlayers.map((pl, ind) => {
          return (
            <Fragment key={pl._id}>
              <TextLink to={"/users/" + pl.slug}>{pl.firstName}</TextLink>
              {ind === otherPlayers.length - 2
                ? ", and "
                : ind === otherPlayers.length - 1
                ? ""
                : ", "}
            </Fragment>
          )
        })}{" "}
        at{" "}
        <TextLink to={"/gyms/" + item.gym.slug}>{item.game.gym.name}</TextLink>
      </>
    )
  }
  switch (fType) {
    case "send":
      return features.length === 1 ? (
        <>
          {action}{" "}
          {features[0][features[0].sendType]?.name ? (
            <TextLink to={featRoute + features[0].slug}>
              {features[0][features[0].sendType]?.name +
                (gymArea ? ` on the ${gymArea.name}` : "")}
            </TextLink>
          ) : (
            <> a {capitalizeText(features[0].sendType)}</>
          )}
        </>
      ) : (
        action + " " + features.length + " " + features[0].type + "s"
      )
    case "session":
      const sends = features[0].sends
      const place = features[0].gym || features[0].guide
      const attOrSend = features[0].sends?.[0] || features[0].attempts?.[0]
      const climbType = attOrSend?.sendType || attOrSend?.climbType
      const name = attOrSend?.[climbType]?.name
      const numTries = features[0].sends?.length || features[0].attempts?.length
      const hasNoSends = features[0].sends?.length === 0
      return (
        <>
          {action}
          {hasNoSends && !!climbType ? " on " : " "}
          {!!climbType && (
            <>
              {`${numTries} ${capitalizeText(climbType)}${
                numTries === 1 ? "" : "s"
              }${place ? (place.type === "gym" ? " at " : " in ") : ""}`}
              {!!place && (
                <TextLink
                  to={`${place.type}s/${place.slug}`}
                >{`${place.name}`}</TextLink>
              )}
            </>
          )}
        </>
      )
    default:
      return features.length === 1 ? (
        <>
          {action}{" "}
          <TextLink to={featRoute + features[0].slug}>
            {features[0].name}
          </TextLink>
        </>
      ) : (
        action +
          " " +
          (features.length ? features.length : "") +
          " " +
          (features[0].type
            ? features[0].type + (features[0].type === "media" ? "" : "s")
            : "")
      )
  }
}

const FeedImage = ({
  feedItem,
  features,
  router,
  isShowingComments,
  fontSize,
  isPublicBoard,
}) => {
  const { insetColor } = useColors()
  const imageObj = getFeedImage(feedItem)
  const resizedImageObj = getResizedImageObj(imageObj)
  const [height, setHeight] = useState(0)
  const [hasMoved, setHasMoved] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const featRoute =
    features[0].type === "media"
      ? "/media/"
      : features[0].type === "send"
      ? "/sends/"
      : "/" + features[0].type + "s/"
  const images = Array.isArray(resizedImageObj)
    ? resizedImageObj
    : [resizedImageObj]
  if (!images?.length) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        bg="brand.blueBackground"
      >
        <img alt="Pebble Climbing Logo" src={Logo} width={100} height={100} />
      </Box>
    )
  }

  if (feedItem.eventType === GAME_WINNER) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        position="relative"
      >
        <AbsoluteGradient />
        <img
          style={{
            objectFit: "contain",
            zIndex: 1,
            position: "relative",
          }}
          alt="Hive Logo"
          src={images[0].url}
          width={150}
          height={100}
        />
      </Box>
    )
  }
  const level = feedItem.gamePyramid?.level
  const isHardGrade = [INDOOR_PROBLEM_GRADE, INDOOR_ROUTE_GRADE].includes(
    feedItem.achievementType,
  )
  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        setHeight((contentRect.bounds.height / contentRect.bounds.width) * 100)
      }}
    >
      {({ measureRef }) => (
        <Box
          ref={measureRef}
          mt={[0, 0, 0]}
          mb={[1, 1, 0]}
          mr={[0, 0, 0]}
          ml={[0, 0, 1]}
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          borderTopRightRadius={"lg"}
          borderBottomRightRadius={isShowingComments ? 0 : [0, 0, "lg"]}
          borderTopLeftRadius={["lg", "lg", 0]}
          overflow="hidden"
        >
          {feedItem.oembed ? (
            <MediaEmbed oembed={feedItem.oembed} isUnstyled />
          ) : (
            <Carousel height={height}>
              {images.map((obj, ind) => {
                return (
                  <div
                    key={obj?.url || feedItem._id}
                    className={styles["main-image"]}
                    onMouseDown={() => {
                      setIsMouseDown(true)
                      setHasMoved(false)
                    }}
                    onMouseMove={() => {
                      if (isMouseDown) {
                        setHasMoved(true)
                      }
                    }}
                    onMouseUp={() => {
                      // can do this because async
                      setIsMouseDown(false)
                      if (hasMoved) {
                        setHasMoved(false)
                        return
                      }
                      router.push({
                        pathname:
                          features[ind].type === "session"
                            ? "users/" + feedItem.user.slug
                            : featRoute + features[ind].slug,
                      })
                    }}
                  >
                    {obj?.url || obj?.thumbnailUrl ? (
                      <SocialImage
                        isContain
                        imageObj={obj}
                        height="100%"
                        width="100%"
                        bg={insetColor}
                      />
                    ) : obj?.video ? (
                      <ReactPlayer
                        url={obj.video}
                        controls
                        width="100%"
                        height="100%"
                        style={{ background: "#000" }}
                      />
                    ) : (
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        borderLeftWidth={1}
                        bg="brand.blueBackground"
                      >
                        <img
                          alt="Pebble Climbing Logo"
                          src={Logo}
                          width={100}
                          height={100}
                        />
                      </Box>
                    )}
                    {!!level && (
                      <Flex
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        align="center"
                        justify="center"
                      >
                        <Heading
                          mt={isPublicBoard ? 9 : 0}
                          textShadow={"0 0 5px #000"}
                          fontSize={fontSize}
                          color={"white"}
                        >
                          Level {level}
                        </Heading>
                      </Flex>
                    )}
                    {!!isHardGrade && (
                      <Flex
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        align="center"
                        justify="center"
                      >
                        <Heading
                          fontSize={50}
                          color="white"
                          // textShadow={isDark ? "0 0 5px #000"}
                        >
                          <ClimbGrade
                            dotSize={50}
                            gym={feedItem.gyms[0] || feedItem.gym}
                            climb={
                              feedItem?.sends[0]?.problem ||
                              feedItem?.sends[0]?.route
                            }
                          />
                        </Heading>
                      </Flex>
                    )}
                  </div>
                )
              })}
            </Carousel>
          )}
        </Box>
      )}
    </Measure>
  )
}

const UserFeedComp = ({ feedItem, loggedInUser, isPublicBoard }) => {
  const { user, gym: feedGym, gyms, eventType, comp } = feedItem
  const gym = feedGym || comp?.gym
  const userName =
    gym && ![ACHIEVEMENT, COMPLETE_PYRAMID, GAME_WINNER].includes(eventType)
      ? gym?.name
      : isPublicBoard
      ? `${user.firstName} ${user.lastName[0] ?? ""}`
      : user?.name
  const picture =
    gym && ![ACHIEVEMENT, COMPLETE_PYRAMID, GAME_WINNER].includes(eventType)
      ? _.get(gym, "popMedia.url")
      : _.get(user, "profile.picture")
  const resizedPicture = imageHandler(picture, {
    resize: { width: 50, fit: "cover" },
  })
  // if multiple gyms, show all
  const pictures = gyms?.map((g) => g.popMedia?.url)
  const userNamesAr = !!gyms?.length && gyms?.map((g) => g.name)

  return (
    <Flex>
      {pictures?.length > 1 ? (
        <AvatarGroup size="md" max={3}>
          {pictures.map((pic, ind) => (
            <Avatar
              borderColor="brand.whiteColor"
              key={ind}
              name={userNamesAr[ind]}
              src={imageHandler(pic, {
                resize: { width: 50, fit: "cover" },
              })}
            />
          ))}
        </AvatarGroup>
      ) : (
        <Avatar name={userName} src={resizedPicture} size="md" />
      )}
      <Box flex={1}>
        {userNamesAr?.length > 1 ? (
          <Heading size="md" as="h4" ml={3} mb={0}>
            {gyms &&
            ![ACHIEVEMENT, COMPLETE_PYRAMID, GAME_WINNER].includes(
              eventType,
            ) ? (
              <span>
                {userNamesAr.map((name, ind) => (
                  <Fragment key={ind}>
                    <TextLink to={"/gyms/" + gyms[ind].slug}>{name}</TextLink>
                    {ind === userNamesAr.length - 2
                      ? " and "
                      : ind === userNamesAr.length - 1
                      ? ""
                      : ", "}
                  </Fragment>
                ))}
              </span>
            ) : (
              // not used but may eventually
              <TextLink to={"/users/" + user.slug}>{userName}</TextLink>
            )}
          </Heading>
        ) : (
          <Heading size="md" as="h4" ml={3} mb={0}>
            {gym &&
            ![ACHIEVEMENT, COMPLETE_PYRAMID, GAME_WINNER].includes(
              eventType,
            ) ? (
              <TextLink to={"/gyms/" + gym.slug}>{userName}</TextLink>
            ) : (
              <TextLink to={"/users/" + user.slug}>{userName}</TextLink>
            )}
          </Heading>
        )}
        <Heading size="xs" as="h5" ml={3}>
          {dayjs(feedItem.date).format(loggedInUser?.isAdmin ? "LLL" : "LL")}
        </Heading>
      </Box>
    </Flex>
  )
}

const FeedItem = ({
  loggedInUser = {},
  gradeType,
  feedItem,
  router,
  isCommentsOpen = false,
  isSmall,
  fontSize,
  badgeFontSize = "36px",
  isPublicBoard = false,
  height,
}) => {
  const pathAr = window.location.href.split("/")
  const [isShowingComments, setIsShowingComments] = useState(
    _.last(pathAr) === "comments" || isCommentsOpen,
  )
  const [isShowingLikes, setIsShowingLikes] = useState(false)
  const [isShowingAllFeatures, setIsShowingAllFeatures] = useState(false)

  const {
    user,
    numComments,
    likes,
    _id,
    slug,
    sends,
    eventType,
    description,
    isCommentsHidden,
    session,
  } = feedItem

  const action = getFeedAction({ feedItem })
  const features = getFeedFeatures(feedItem)
  const descMD = description || features[0]?.description
  const desc = descMD ? markdownToHTML(descMD) : null
  if (!features[0]) {
    return null
  }
  const featRoute =
    features[0].type === "media"
      ? "/media/"
      : features[0].type === "send"
      ? "/sends/"
      : "/" + features[0].type + "s/"
  const fType = features[0].type
  const gymArea =
    (fType === "send" && features[0].problem?.gymArea) ||
    features[0].route?.gymArea
  const isShowingSession =
    (features[0].type === "session" && eventType !== ACHIEVEMENT) ||
    eventType === SESSION_WORKOUT
  const isWorkout = eventType === CREATE_WORKOUT
  return (
    <Box mt={5}>
      <Card height={height ?? "auto"} overflow={"hidden"}>
        <Box
          flexDirection={["column-reverse", "column-reverse", "row"]}
          display="flex"
          flexWrap={["wrap", "wrap", "nowrap"]}
        >
          <Cell
            display="flex"
            col={[8, 5, 7]}
            flex={1}
            flexDirection="column"
            maxWidth={[
              "100%",
              "100%",
              isSmall ? "calc(100% - 150px)" : "calc(100% - 300px)",
            ]}
            mt={[3, 0, 0]}
          >
            <UserFeedComp
              feedItem={feedItem}
              loggedInUser={loggedInUser}
              isPublicBoard={isPublicBoard}
            />
            <Heading
              size={fontSize ? "lg" : null}
              fontSize={fontSize}
              as="h3"
              mt={5}
              mb={2}
            >
              {getTitle({
                eventType,
                fType,
                gymArea,
                features,
                featRoute,
                user,
                action,
                item: feedItem,
              })}
            </Heading>
            <FeedItemStats feedItem={feedItem} features={features} />
            {features.length === 1 ||
            feedItem.eventType === GYM_NOTIFICATION ? (
              <Text
                className="markdown-body"
                noOfLines={isPublicBoard ? 2 : 4}
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            ) : (
              <div>
                <UnorderedList>
                  {(isShowingAllFeatures ? features : features.slice(0, 5)).map(
                    (feat) => {
                      const climb =
                        feat.type === "send"
                          ? feat.sendType === "problem"
                            ? feat.problem
                            : feat.route
                          : feat
                      return (
                        <ListItem key={feat.slug}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems={"center"}
                          >
                            <Heading as="h5" size="sm" islight="true">
                              <TextLink to={featRoute + feat.slug}>
                                {feat.name +
                                  (climb.gymArea
                                    ? ` in the ${climb.gymArea.name}`
                                    : "")}
                              </TextLink>
                            </Heading>
                            {climb.grade ? (
                              <span>
                                <ClimbGrade
                                  dotSize={16}
                                  ttId={feat.slug}
                                  climb={climb}
                                  gym={climb.gym}
                                  gradeType={gradeType}
                                />
                              </span>
                            ) : null}
                          </Box>
                        </ListItem>
                      )
                    },
                  )}
                  {isShowingAllFeatures || features.length < 6 ? null : (
                    <ListItem>
                      <Heading as="h5" size="sm">
                        <TextLink
                          onClick={() => {
                            setIsShowingAllFeatures(true)
                          }}
                        >
                          Show {features.length - 5} more...
                        </TextLink>
                      </Heading>
                    </ListItem>
                  )}
                </UnorderedList>
              </div>
            )}
            {feedItem.link && (
              <>
                <Box mt={2} />
                <Text noOfLines={1}>
                  <Link
                    isExternal={!feedItem.link.includes(siteURL)}
                    href={feedItem.link}
                  >
                    {feedItem.link}
                  </Link>
                </Text>
              </>
            )}
            {!isPublicBoard && (
              <Box mt="auto">
                {!isCommentsHidden && !!loggedInUser && (
                  <Box
                    display="flex"
                    justifyContent={"flex-end"}
                    alignItems="center"
                    mt={4}
                  >
                    <Heading as="h5" mr="auto" variant="link" size="sm" mb={0}>
                      {likes.length !== 0 ? (
                        <span
                          onClick={() => {
                            setIsShowingLikes(true)
                          }}
                        >
                          {likes.length +
                            " fist bump" +
                            (likes.length === 1 ? "" : "s")}
                        </span>
                      ) : null}
                      {likes.length !== 0 && numComments !== 0 ? " - " : null}
                      {numComments !== 0 ? (
                        <span
                          data-tip
                          data-tooltip-id={"comment-" + slug + "-tt"}
                          onClick={() => {
                            setIsShowingComments(!isShowingComments)
                          }}
                        >
                          {numComments +
                            " comment" +
                            (numComments === 1 ? "" : "s")}
                        </span>
                      ) : null}
                    </Heading>
                    <FeedItemLikesCont
                      sendSlug={sends && sends.length === 1 && sends[0].slug}
                      loggedInUser={loggedInUser}
                      feedItemId={_id}
                      render={({ likeFeedItem, unlikeFeedItem }) => (
                        <FeedItemLikes
                          loggedInUser={loggedInUser}
                          feedItemLikes={likes}
                          feedItemSlug={slug}
                          likeFeedItem={likeFeedItem}
                          unlikeFeedItem={unlikeFeedItem}
                        />
                      )}
                    />
                    <Box mx={3}>
                      <IconToggle
                        data-tip
                        data-tooltip-id={"comment-" + slug + "-tt"}
                        aria-label="comment"
                        onClick={() => {
                          setIsShowingComments(!isShowingComments)
                        }}
                        icon={faComment}
                      />
                      <Tooltip
                        place="bottom"
                        class="card-tooltip"
                        id={"comment-" + slug + "-tt"}
                      >
                        <Heading as="h6">View Comments</Heading>
                      </Tooltip>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Cell>
          <Box
            position={"relative"}
            display="flex"
            width={[
              "auto",
              "auto",
              isSmall ? "calc(150px + 1rem)" : "calc(300px + 1rem)",
            ]}
            height={isPublicBoard ? height : "auto"}
            minHeight={isPublicBoard ? height : ["300px", "300px", "200px"]}
            borderBottomRightRadius={[0, "md", "md"]}
            borderTopLeftRadius={["md", 0, 0]}
            borderTopRightRadius={"md"}
            overflow="hidden"
            m={-5}
            ml={[-5, 1, 1]}
            mb={[1, -5, -5]}
          >
            {!!isShowingSession ? (
              <SessionCard
                isPublicBoard={isPublicBoard}
                chartHeight={height}
                isBox
                sessionId={session?._id || features[0]?._id}
                isSmall={isSmall}
              />
            ) : isWorkout ? (
              <Box
                position={"relative"}
                alignSelf="stretch"
                flex={1}
                width={["auto", "auto"]}
                ml={0}
                alignItems="center"
                display="flex"
              >
                <AbsoluteGradient />
                <Box p={3} position="relative" zIndex={1}>
                  <WorkoutCard workout={feedItem.workout} isBox />
                </Box>
              </Box>
            ) : (
              <FeedImage
                feedItem={feedItem}
                features={features}
                router={router}
                isShowingComments={isShowingComments}
                fontSize={badgeFontSize}
                isPublicBoard={isPublicBoard}
              />
            )}
          </Box>
        </Box>
        <Box
          mx={-5}
          mt={5}
          mb={-5}
          borderTopWidth={isShowingComments ? "1px" : 0}
          borderTopColor="brands.headingLineColor"
        >
          <FeedItemComments
            isShowingComments={isShowingComments}
            feedItem={feedItem}
            loggedInUser={loggedInUser}
          />
        </Box>
      </Card>
      <UserListModal
        isOpen={isShowingLikes}
        title="Fist Bumps:"
        users={likes}
        onClose={() => {
          setIsShowingLikes(false)
        }}
      />
    </Box>
  )
}

export default withRouter(FeedItem)
