export const CREATE_PROBLEM = "CREATE_PROBLEM"
export const CREATE_ROUTE = "CREATE_ROUTE"
export const ADD_MEDIA = "ADD_MEDIA"
export const CREATE_AREA = "CREATE_AREA"
export const CREATE_GUIDE = "CREATE_GUIDE"
export const CREATE_SEND = "CREATE_SEND"
export const START_SESSION = "START_SESSION"
export const CREATE_GYM = "CREATE_GYM"
export const CREATE_GYM_AREA = "CREATE_GYM_AREA"
export const RESET_GYM_AREA = "RESET_GYM_AREA"
export const GYM_NOTIFICATION = "GYM_NOTIFICATION"
export const COMP_NOTIFICATION = "COMP_NOTIFICATION"
export const COMP_JUDGE_NOTIFICATION = "COMP_JUDGE_NOTIFICATION"
export const SESSION_WORKOUT = "SESSION_WORKOUT"
export const GYM_DATA_OPT_IN = "GYM_DATA_OPT_IN"
export const CREATE_WORKOUT = "CREATE_WORKOUT"
export const SENT_HARDEST_GRADE = "SENT_HARDEST_GRADE"
export const ACHIEVEMENT = "ACHIEVEMENT"
export const BETA_VIDEO = "BETA_VIDEO"
export const SCANNER_ACTIVITY = "SCANNER_ACTIVITY"
export const SURVEY = "SURVEY"
export const COMPLETE_PYRAMID = "COMPLETE_PYRAMID"
export const GAME_WINNER = "GAME_WINNER"

export const publicFeedItemTypes = [
  RESET_GYM_AREA,
  GYM_NOTIFICATION,
  COMP_NOTIFICATION,
]

export const getFeedItemTypeName = (eventType) => {
  switch (eventType) {
    case CREATE_AREA:
      return "area"
    case CREATE_GUIDE:
      return "guide"
    case CREATE_PROBLEM:
      return "problem"
    case BETA_VIDEO:
    case ADD_MEDIA:
      return "media"
    case CREATE_ROUTE:
      return "route"
    case CREATE_SEND:
      return "send"
    case START_SESSION:
      return "session"
    case CREATE_GYM:
      return "gym"
    case CREATE_GYM_AREA:
      return "gymArea"
    case RESET_GYM_AREA:
      return "gymArea"
    case GYM_NOTIFICATION:
    case COMPLETE_PYRAMID:
    case GAME_WINNER:
      return "gym"
    case COMP_NOTIFICATION:
    case COMP_JUDGE_NOTIFICATION:
      return "comp"
    case SESSION_WORKOUT:
      return "workout"
    case GYM_DATA_OPT_IN:
      return "gymDataOptIn"
    case CREATE_WORKOUT:
      return "createWorkout"
    case ACHIEVEMENT:
      return "achievementType"
    case SURVEY:
      return "survey"
    default:
      return ""
  }
}

export const commentableFeedItems = [
  "problem",
  "route",
  "area",
  "guide",
  "media",
  "send",
]

// export default {
//   CREATE_PROBLEM,
//   CREATE_ROUTE,
//   ADD_MEDIA,
//   CREATE_AREA,
//   CREATE_GUIDE,
//   CREATE_SEND,
//   START_SESSION,
//   SESSION_WORKOUT,
//   CREATE_WORKOUT,
//   getFeedItemTypeName,
// }
